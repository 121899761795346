/****************************** components ******************************/

@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
@import "./components/case"; //例子

/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

html {
    min-width: 1200px;
}

.w1200 {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.header {
    width: 100%;
    height: 161px;
    background: url(../images/header_bg.jpg) repeat-x top center;
    line-height: 33px;
    color: #fff;
}

.header_top {
    height: 33px;
}

.header_top span {
    font-size: 12px;
}

.header_top p {
    font-size: 16px;
}

.header_top p span {
    font-size: 16px;
    color: #ffe599;
}

.header_bottom {
    height: 128px;
    margin-top: 40px;
    color: #1a57a2;
}

.header_logo {
    width: 390px;
}

.header_logo img {
    width: 64px;
    height: auto;
    float: left;
}

.header_logo .p1 {
    font-size: 26px;
    line-height: 22px;
    display: block;
    padding-left: 74px;
}

.header_logo .p2 {
    font-size: 12px;
    line-height: 22px;
    display: inline;
    margin-left: 10px;
}

.header_nav ul {}

.header_nav ul li {
    float: left;
    height: 92px;
}

.header_nav ul li a {
    display: block;
    width: 100px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #1a57a2;
    font-size: 16px;
}

.header_nav ul li a:hover {
    color: #fff;
    background-color: #073763;
    border-radius: 50px;
}

.box_banner {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.banner_inner {
    height: 530px;
    width: 100%;
    margin: 0 auto
}

.banner_img {
    width: 20000px
}

.banner_img li {
    width: 100%;
}

.banner .child1 {
    position: absolute;
    width: 588px;
    height: 425px;
    left: 66px;
    top: 0
}

.banner .child2 {
    position: absolute;
    width: 654px;
    height: 425px;
    left: 343px;
    top: 0
}

.banner_common {
    position: relative;
    width: 990px;
    margin: 0 auto
}

.banner_pre {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: 180px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: url(../images/banner_btn_l_24.png) no-repeat;
    opacity: .5;
    filter: alpha(opacity=50)
}

.banner_next {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: 180px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: url(../images/banner_btn_r_24.png) no-repeat;
    opacity: .5;
    filter: alpha(opacity=50)
}

.banner_pre,
.banner_next {
    &:hover {
        opacity: 1;
        filter: alpha(opacity=100)
    }
}

.banner .control {
    width: 130px;
    position: absolute;
    top: 473px;
    left: 50%;
    margin-left: -40px
}

.banner .control li {
    float: left;
    margin-left: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    background: #fff
}

.banner .control li a {
    display: none
}

.banner .control li.current {
    background: #ff7f02
}

.main {
    width: 100%;
    position: relative;
}

.box_banner .banner_words {
    width: 435px;
    position: absolute;
    left: 65%;
    top: 160px;
}

.banner_words i {
    color: #1c4587;
    font-size: 14px;
}

.banner_words p {
    width: 435px;
    text-align: center;
    line-height: 140%;
}

.banner_words .p1 {
    line-height: 30px;
}

.banner_words .p2 {
    font-size: 50px;
    color: #073763;
    font-weight: bold;
    border-top: 1px dotted #000;
    border-bottom: 1px dotted #000;
    line-height: 70px;
}

.banner_words .p2 b {
    color: #ff9900;
}

.banner_words .p3 {
    font-size: 15px;
    color: #073763;
    line-height: 30px;
}

.search {
    width: 100%;
    height: 90px;
    margin: 0 auto;
    background-color: #f0f1f6;
}

.search input {
    float: left;
    margin-top: 30px;
}

.search input[type="text"] {
    width: 300px;
    height: 28px;
    border: #d3d3d3 1px solid;
    line-height: 28px;
    @include placeholder(#1d7ad9, 14px);
    text-indent: 1em;
}

.search input[type="button"] {
    width: 50px;
    height: 28px;
    background-color: #1d7ad9;
    color: #fff;
    line-height: 28px;
    text-align: center;
    border: none;
    position: relative;
    left: -50px;
}

.search ul {
    padding-top: 35px;
}

.search ul li {
    float: left;
    margin-left: 15px;
}

.search ul li:first-child {
    margin-left: 0px;
}

.search ul li a {
    font-size: 12px;
    color: #5a5a5a;
    &:hover {
        color: #53d0fe;
    }
}

.search img {
    position: absolute;
    top: 30px;
    right: 0px;
}

.about {
    margin-top: 30px;
}

.about_left {
    width: 670px;
}

.about_left h4 {
    font-weight: normal;
    color: #2795e0;
}

.about_left h5 {
    font-size: 18px;
    font-weight: normal;
    color: #2795e0;
}

.about_left p {
    font-size: 14px;
    line-height: 25px;
}

.about_left li {
    margin-left: 30px;
    float: left;
}

.about_left a {
    display: block;
    width: 150px;
    height: 50px;
    line-height: 50px;
    color: #1c4587;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ebeef4;
    border-radius: 3px;
    -webkit-transition: background-color 0.4s ease 0s;
    -o-transition: bbackground-color 0.4s ease 0s;
    transition: background-color 0.4s ease 0s;
    &:hover {
        color: #fff;
        background-color: #ff6600;
    }
}

.about_left li:first-child {
    margin-left: 0px;
    color: #434343;
    background-color: #ffe599;
}

.about_right {
    width: 520px;
    height: 328px;
}

.s1 {
    position: fixed;
    z-index: 10;
    left: 50%;
    top: 50%;
    margin: -150px 0 0 -250px;
    width: 500px;
    height: 300px;
    background: rgba(255, 255, 255, .7);
    box-shadow: 0 0 15px rgba(0, 0, 0, .3);
}

.s1 p {
    margin: 0;
    padding: 50px 0 20px 0;
    font-family: Arial;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}

.s1 span {
    display: block;
    font-family: Arial;
    font-size: 25px;
    color: #666;
    text-align: center;
}

.s1 a {
    margin: 20px auto 0 auto;
    display: block;
    width: 220px;
    height: 50px;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    text-decoration: none;
    font-family: Arial;
    font-size: 25px;
    background: #f88371;
}

.s1 a:hover {
    background: #ed6f5c;
}

.slides-box {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.slides1 {
    position: relative;
    height: 100%;
    width: 100%;
    margin: auto;
    float: left;
}

.slides1 li {
    position: absolute;
    width: 100%;
    height: 100%;
}

.slides1 li img {
    display: block;
    width: 100%;
    margin: auto;
}

.prev,
.next {
    position: absolute;
    z-index: 99;
    top: 50%;
    margin-top: -40px;
    left: 0;
    height: 70px;
    width: 30px;
    color: #fff;
    cursor: pointer;
    background-color: #000;
    opacity: .5;
}

.prev:hover,
.next:hover {
    opacity: .7;
}

.prev {
    background-image: url("data:image/gif;base64,R0lGODlhHgBGAIABAP///////yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwMTQgNzkuMTU2Nzk3LCAyMDE0LzA4LzIwLTA5OjUzOjAyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjAzODAxMTc0MDcyMDY4MTE5MkIwQTM1OTQ0MTQ3NDEwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjY0M0UwQjkxMEQ3RTExRTVCOTJFQkFCNkU5QTFCNUE5IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjY0M0UwQjkwMEQ3RTExRTVCOTJFQkFCNkU5QTFCNUE5IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEFCRDY2OUNGM0REMTFFMkFCOTg5QTlBMkVEQjFBRjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEFCRDY2OURGM0REMTFFMkFCOTg5QTlBMkVEQjFBRjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQBAAABACwAAAAAHgBGAAACUIyPqcvtD6OctNqLs968+w+G4kiWIYB6aMqtrOYCrTuv9ZvFt7zpPf2zBXEY31Al3CGTQ56yY2wuiTng8yqFWo9Lk/cLDovH5LL5jE6r15oCADs=");
}

.next {
    left: auto;
    right: 0;
    background-image: url("data:image/gif;base64,R0lGODlhHgBGAIABAP///////yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwMTQgNzkuMTU2Nzk3LCAyMDE0LzA4LzIwLTA5OjUzOjAyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjAzODAxMTc0MDcyMDY4MTE5MkIwQTM1OTQ0MTQ3NDEwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg0QTYyN0Y0MEQ3RTExRTVBMTAyQURBQ0QxRjY2NTc1IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg0QTYyN0YzMEQ3RTExRTVBMTAyQURBQ0QxRjY2NTc1IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEFCRDY2OUNGM0REMTFFMkFCOTg5QTlBMkVEQjFBRjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEFCRDY2OURGM0REMTFFMkFCOTg5QTlBMkVEQjFBRjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQBAAABACwAAAAAHgBGAAACV4yPqcvtD6OctNqLs968+w+G4kiWFIB+aNqtLOcCXjy79Xq/G93aPf7TaXgwX1GYMQ6VGGKSeXE2oRZplFqxVrEn7kSb9X6BR5P5jE6r1+y2+w2Py+fmAgA7");
}

.pagination {
    position: absolute;
    bottom: 5px;
    z-index: 99;
}

.pagination li {
    padding: 5px 3px;
    float: left;
}

.pagination li a {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 20px;
    background: #f58220;
    color: #fff;
    text-align: center;
    font-family: Arial;
    text-decoration: none;
}

.pagination .active {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 25px;
    background: #bed742;
    position: relative;
    top: -2.5px;
}

.product {
    width: 100%;
    height: 715px;
    margin: 50px auto 0;
    background: url(../images/probg.jpg) no-repeat top center;
    text-align: center;
    color: #fff;
}

.product .w1200 {
    padding-top: 1px;
}

.product h4 {
    margin-top: 55px;
    font-size: 24px;
    line-height: 34px;
    font-weight: normal;
}

.product h5 {
    font-size: 20px;
    font-weight: normal;
}

.product .fa-caret-down {
    font-size: 30px;
    line-height: 30px;
}

.product ul {
    width: 620px;
    margin: 30px auto 0;
}

.product ul li {
    float: left;
    margin-left: 15px;
}

.product ul li:first-child {
    margin-left: 0px;
}

.product ul li a {
    display: block;
    width: 110px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #fff;
    -webkit-transition: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
    -o-transition: border-color 0.4s ease 0s, bbackground-color 0.4s ease 0s;
    transition: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
    &:hover {
        background-color: #4aa2ec;
        border-color: #4aa2ec;
    }
}

.wrap {
    padding: 10px;
}

.dowebok {
    width: 1200px;
    margin: 50px auto 70px;
    font-size: 0;
}

.dowebok img {
    vertical-align: top;
}

.str_wrap {
    overflow: hidden;
    //zoom:1;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    white-space: nowrap;
}

.str_wrap.str_active {}

.str_move {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    cursor: move;
}

.str_move_clone {
    display: inline-block;
    //display:inline;
    //zoom:1;
    vertical-align: top;
    position: absolute;
    left: 100%;
    top: 0;
}

.str_vertical .str_move_clone {
    left: 0;
    top: 100%;
}

.str_down .str_move_clone {
    left: 0;
    bottom: 100%;
}

.str_vertical .str_move,
.str_down .str_move {
    white-space: normal;
    width: 100%;
}

.str_static .str_move,
.no_drag .str_move,
.noStop .str_move {
    cursor: inherit;
}

.str_wrap img {
    max-width: none;
}

.product .dowebok ul {
    height: 224px;
}

.product .dowebok ul li {
    float: left;
    width: 220px;
    height: 224px;
}

.product .dowebok ul li a {
    width: 220px;
    height: 200px;
    border: none;
}

.product .dowebok ul li img {
    width: 220px;
    height: 200px;
}

.product .pro_title {
    display: block;
    color: #fff;
    font-size: 16px;
    width: 220px;
    line-height: 24px;
    height: 24px;
    text-align: center;
    ;
}

.product .more {
    display: block;
    width: 150px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background-color: #4a86e8;
    -moz-box-shadow: rgba(0, 0, 0, 0.6) 1px 1px 4px 0px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.6) 1px 1px 4px 0px;
    box-shadow: rgba(0, 0, 0, 0.6) 1px 1px 4px 0px;
    -webkit-transition: background-color 0.4s ease 0s;
    -o-transition: background-color 0.4s ease 0s;
    transition: background-color 0.4s ease 0s;
    &:hover {
        background-color: #ff9900;
    }
}

.case {
    width: 100%;
    margin: 0 auto;
}

.case_title {
    width: 750px;
    height: 252px;
    margin-top: 50px;
    background: url(../images/casebg.jpg) no-repeat top center;
    background-position: 50% 50%;
}

.case_title .circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    top: 25px;
    text-align: center;
    color: #3c78d8;
}

.circle .p1 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 60px;
}

.circle .p2 {
    font-size: 20px;
    line-height: 30px;
}

.circle .p3 {
    font-size: 30px;
    line-height: 30px;
}

.case_title ul li {
    float: left;
    margin-left: 22px;
    margin-top: 70px;
}

.case_title ul li:first-child {
    margin-left: 0px;
}

.case_title ul li a {
    display: block;
    width: 169px;
    height: 140px;
    border: 1px solid #fff;
    &:hover {
        border: 1px solid #ff6600;
    }
}

.case_right {
    width: 410px;
    margin-top: 50px;
}

.case_right h3 {
    font-size: 22px;
    font-weight: normal;
    line-height: 34px;
    color: #4aa2ec;
}

.case_right h3 a {
    display: block;
    width: 60px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    color: #3c78d8;
    margin-top: 5px;
    border: 1px solid #4aa2ec;
}

.rec_news {
    margin-top: 30px;
}

.rec_news ul li div img {
    float: left;
    width: 130px;
    height: 90px;
    margin-right: 10px;
}

.rec_news .p1 {
    font-size: 14px;
    color: #4aa2ec;
    line-height: 30px;
}

.rec_news .p2 {
    font-size: 12px;
    color: #666;
}

.case_right .newslist li {
    height: 36px;
    background: url(../images/libg.png) no-repeat left center;
    border-bottom: 1px dotted #e2e2e2;
}

.case_right .newslist li:first-child {
    margin-top: 10px;
}

.case_right .newslist li a {
    font-size: 14px;
    line-height: 36px;
    color: #5a5a5a;
    margin-left: 25px;
    &:hover {
        color: #4a91e0;
    }
}

.case_right .newslist li span {
    font-size: 12px;
    line-height: 36px;
    color: #ccc;
}

.flink {
    width: 100%;
}

.flink .w1200 {
    padding-top: 100px;
}

.flink h3 {
    font-size: 26px;
    color: #3c78d8;
    float: left;
}

.flink h3 span {
    display: inline-block;
    width: 86px;
    height: 20px;
    line-height: 20px;
    background: #3c78d8;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    position: relative;
    bottom: 4px;
}

.flink ul {
    float: left;
    position: relative;
    top: 8px;
    width: 1030px;
}

.flink ul li {
    float: left;
    margin-left: 15px;
}

.flink ul li a {
    font-size: 14px;
    color: #5a5a5a;
    &:hover {
        color: #47cdff;
    }
}

.footer {
    width: 100%;
    height: 187px;
    margin: 0 auto;
    background-color: #003b78;
    background-image: url(../images/footerbg.jpg);
    background-repeat: repeat-x;
}

.footer_left {
    width: 520px;
}

.footer_left p {
    font-size: 14px;
    color: #fff;
}

.footer_left p:first-child {
    margin-top: 70px;
}

.footer_right {
    width: 414px;
    height: 187px;
    position: relative;
}

.footer_right img {
    width: 47px;
    height: 47px;
    float: left;
    margin-top: 70px;
}

.footer_right .p1 {
    font-size: 18px;
    color: #fff;
    position: absolute;
    top: 75px;
    left: 50px;
}

.footer_right .p2 {
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 95px;
    left: 50px;
}

.footer_right .p3 {
    font-size: 32px;
    color: #fff;
    position: absolute;
    font-weight: bold;
    top: 70px;
    left: 170px;
}

.page_banner {
    width: 100%;
    height: 350px;
    margin: 0 auto;
    background: url(../images/pagemainbg.jpg);
}

.main {
    width: 100%;
    height: auto;
    margin: 35px auto 0;
}

.i_main {
    margin-top: 0px;
}

.main_l {
    width: 250px;
    height: auto;
}

.main_l_title {
    width: 250px;
    height: 77px;
    background: url(../images/pagebg.png) no-repeat left top;
    text-align: center;
}

.main_l_title .p1 {
    color: #305692;
    font-size: 27px;
    padding-top: 13px;
}

.main_l_title .p2 {
    color: #305692;
    font-size: 12px;
}

.main_l ul li {
    width: 245px;
    height: 44px;
    margin-left: 5px;
    margin-top: 5px;
}

.main_l ul li a {
    display: block;
    width: 245px;
    height: 44px;
    line-height: 44px;
    background-color: #073763;
    text-align: center;
    color: #fff;
    font-size: 14px;
    &:hover {
        background-color: #ff9900;
    }
}

.main_l .main_left_message {
    width: 246px;
    height: 100px;
    margin-left: 5px;
    margin-top: 10px;
    background: url(../images/message.jpg);
}

.main_left_message .p1 {
    font-size: 19px;
    color: #fff;
    font-weight: bold;
    margin-left: 20px;
    padding-top: 25px;
}

.main_left_message .p2 {
    font-size: 17px;
    color: #fff;
    margin-left: 20px;
}

.contact {
    margin-left: 10px;
}

.contact p {
    font-size: 14px;
    line-height: 28px;
}

.contact p:first-child {
    padding-top: 5px;
}

.main_right {
    width: 910px;
    height: auto;
}

.crumbs {
    width: 910px;
    height: 50px;
    background: url(../images/positionbg.jpg) no-repeat top left;
    margin-top: 8px;
    border-bottom: 1px solid #ccc;
}

.crumbs a {
    display: inline;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    margin-left: 60px;
    line-height: 44px;
    position: relative;
    top: 6px;
}

.crumbs a:nth-child(3) {
    margin-left: 0px;
}

.crumbs a:nth-child(5) {
    margin-left: 0px;
}

.crumbs span {
    position: relative;
    top: 6px;
}

.page_content {
    width: 910px;
    height: auto;
    margin-top: 50px;
}

.page_content p {
    font-size: 14px;
    line-height: 28px;
    color: #5a5a5a;
    text-indent: 2em;
    padding: 0 10px;
}

.page_content img {
    max-width: 900px;
    display: block;
    margin: 50px auto;
}

.page_prolist {
    width: 910px;
    height: auto;
    margin-top: 10px;
}

.page_prolist ul {}

.page_prolist ul li {
    float: left;
    border: 1px solid #eee;
    width: 212px;
    height: 250px;
    margin-left: 17px;
    margin-top: 17px;
    &:hover {
        border-color: #3c78d8;
    }
}

.page_prolist ul li:first-child {
    margin-left: 0px;
}

.page_prolist ul li:nth-child(5) {
    margin-left: 0px;
}

.page_prolist ul li .img {
    display: block;
    width: 180px;
    height: 190px;
    margin: 15px auto 0px;
}

.page_prolist ul li .img img {
    display: block;
    width: 180px;
    height: 190px;
}

.page_prolist ul li .pro_title {
    display: block;
    font-size: 14px;
    color: #5a5a5a;
    text-align: center;
    line-height: 40px;
}

.page {
    clear: both;
    font-size: 14px;
    margin: 20px auto;
    color: #5a5a5a;
    text-align: center;
    padding: 30px 0;
}

.page .current {
    color: #198ede;
    border-color: #198ede;
}

.page span {
    display: inline-block;
    padding: 10px;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    margin: 3px;
}

.page a {
    display: inline-block;
    padding: 10px;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    margin: 3px;
    text-decoration: none;
    &:hover {
        color: #198ede;
        border-color: #198ede;
    }
}

.main_new_list {
    width: 910px;
    height: auto;
    margin-top: 50px;
}

.main_new_list ul {}

.main_new_list ul li {
    border-bottom: 1px dotted #dbd4cd;
}

.main_new_list ul li div {
    width: 60px;
    height: 60px;
    background-color: #198ede;
    text-align: center;
    color: #fff;
    margin-left: 30px;
    margin-top: 20px;
}

.main_new_list ul li div span {
    font-size: 12px;
    position: relative;
    top: 7px;
}

.main_new_list ul li div span:first-child {
    font-size: 24px;
}

.main_new_list ul li a {
    display: inline-block;
    font-size: 18px;
    color: #5a5a5a;
    margin-left: 40px;
    padding-top: 20px;
    &:hover {
        color: #1c4587;
    }
}

.main_new_list ul li p {
    font-size: 12px;
    color: #949494;
    padding-top: 10px;
    padding-bottom: 26px;
    margin-left: 130px;
}

.lxwm_content {
    width: 910px;
    height: auto;
    margin-top: 50px;
}

.lxwm_content_left {
    width: 373px;
    height: 438px;
}

.lxwm_content_right {
    width: 480px;
    height: auto;
}

.lxwm_content_right h3 {
    font-size: 20px;
    color: #666;
    text-align: center;
    font-weight: normal;
    width: 477px;
    height: 54px;
    line-height: 54px;
    border: 1px solid #3c78d8;
    border-radius: 3px;
}

.lxwm_content_right p {
    font-size: 14px;
    color: #5a5a5a;
    line-height: 24px;
    margin-top: 45px;
    margin-bottom: 45px;
}

.lxwm_content_right span img {
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
    margin-right: 15px;
}

.lxwm_content_right span {
    font-size: 16px;
    color: #1c4587;
    line-height: 28px;
}

.zxly_content {
    width: 910px;
    height: 442px;
    margin-top: 50px;
    background: url(../images/zxlybg.jpg);
}

.zxly_content_left {
    width: 500px;
    height: auto;
    margin-left: 20px;
    margin-top: 45px;
}

.zxly_content_left p {
    font-size: 14px;
    color: #fff;
    padding: 10px 0;
}

.zxly_content_left input {
    width: 500px;
    height: 37px;
    line-height: 37px;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    text-indent: 10px;
}

.zxly_content_left textarea {
    width: 500px;
    height: 162px;
    line-height: 37px;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    text-indent: 10px;
}

.zxly_content_left .send {
    display: block;
    width: 500px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    background-color: #3c78d8;
    margin-top: 12px;
    border-radius: 5px;
    &:hover {
        background-color: #1bc1ff;
    }
}

.zxly_content_right {
    margin-right: 90px;
}

.zxly_content_right .circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    position: relative;
    top: 115px;
    text-align: center;
    color: #3c78d8;
}

.show_content {}

.show_content h3 {
    font-size: 21px;
    color: #426100;
    text-align: center;
    font-weight: normal;
    margin-top: 25px;
}

.show_content .tips {
    text-align: center;
    margin-top: 20px;
}

.show_content .des {
    font-size: 14px;
    color: #868686;
    line-height: 27px;
    padding: 5px;
    border-top: 1px dotted #e5e5e5;
    border-bottom: 1px dotted #e5e5e5;
    text-indent: 2em;
    margin-top: 20px;
}

.show_content .show_c_content {
    font-size: 14px;
    color: #5a5a5a;
    text-indent: 2em;
    margin-top: 20px;
}

.show_c_content a {
    color: #1c4587;
    &:hover {
        color: #5a5a5a;
    }
}

.show_product {
    width: 865px;
    margin: 0 auto;
}

.show_product h3 {
    font-size: 21px;
    color: #426100;
    text-align: center;
    font-weight: normal;
    margin-top: 25px;
}

.show_product .tips {
    text-align: center;
    margin-top: 10px;
    color: #ccc;
}

.show_product .des {
    font-size: 14px;
    color: #868686;
    line-height: 27px;
    padding: 5px;
    border: 1px dotted #e5e5e5;
    margin-top: 10px;
}

.show_product .pro_img {
    display: block;
    margin: 100px auto;
}

.show_product .show_c_content {
    font-size: 14px;
    color: #5a5a5a;
    line-height: 28px;
}

.show_c_content a {
    color: #1c4587;
    &:hover {
        color: #5a5a5a;
    }
}

.flexslider {
    margin: 0px auto;
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
    zoom: 1;
}

.flexslider .slides li {
    width: 100%;
    height: 100%;
}

.flex-direction-nav a {
    width: 70px;
    height: 70px;
    line-height: 99em;
    overflow: hidden;
    margin: -35px 0 0;
    display: block;
    background: url(images/ad_ctr.png) no-repeat;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .3s ease;
    border-radius: 35px;
}

.flex-direction-nav .flex-next {
    background-position: 0 -70px;
    right: 0;
}

.flex-direction-nav .flex-prev {
    left: 0;
}

.flexslider:hover .flex-next {
    opacity: 0.8;
    filter: alpha(opacity=25);
}

.flexslider:hover .flex-prev {
    opacity: 0.8;
    filter: alpha(opacity=25);
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
    opacity: 1;
    filter: alpha(opacity=50);
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
}

.flex-control-nav li {
    margin: 0 2px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.flex-control-paging li a {
    background: url(../images/dot.png) no-repeat 0 -16px;
    display: block;
    height: 16px;
    overflow: hidden;
    text-indent: -99em;
    width: 16px;
    cursor: pointer;
}

.flex-control-paging li a.flex-active,
.flex-control-paging li.active a {
    background-position: 0 0;
}

.flexslider .slides a img {
    width: 100%;
    height: 500px;
    display: block;
}


/****************************** media ******************************/

// @import "./components/media";
